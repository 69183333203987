<template>
  <b-card-code>
    <div class="d-flex justify-content-end">
      <b-button class="ml-1" @click="goBack()" size="sm" variant="primary"
        >Back</b-button
      >
    </div>
    <validation-observer ref="VulnAddForm" #default="{ invalid }">
      <b-form class="auth-login-form mt-2" @submit.prevent="addVulnerability">
        <b-form-group label="Name" label-for="Name">
          <template #label>
            <span>Name</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="name"
            rules="required"
          >
            <!-- <b-form-input
              v-model="form.name"
              :state="errors.length > 0 ? false : null"
            ></b-form-input> -->
            <v-select
              taggable
              v-model="form.name"
              label="text"
              :options="vuln_options"
              @search="fetchOptions"
              @option:selected="loadVulnerability"
              @input="onSelect"
              autocomplete
              state="errors.length > 0 ? false:null"
            />
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="CVSS Score" label-for="cvss_score">
          <b-form-input
            id="cvss_score"
            v-model="form.cvss_score"
            name="cvss_score"
          />
        </b-form-group>

        <b-form-group label="OWASP Category" label-for="owasp_category">
          <v-select
            v-model="form.owasp_categories"
            multiple
            label="text"
            :options="owasp_options"
            @search="fetchOwaspOptions"
            autocomplete
          />
        </b-form-group>

        <b-form-group label="CVE" label-for="CVE">
          <b-form-textarea v-model="form.cve"></b-form-textarea>
        </b-form-group>
        <b-form-group label="Synopsis" label-for="synopsis">
          <quill-editor v-model="form.synopsis" :options="editorOption1">
            <div id="toolbar1" slot="toolbar">
              <button class="ql-bold">Bold</button>
              <button class="ql-italic">Italic</button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-list" value="ordered"></button>
            </div>
          </quill-editor>
          <!-- <b-form-textarea v-model="form.synopsis"></b-form-textarea> -->
        </b-form-group>
        <b-form-group label="Description" label-for="Description">
          <template #label>
            <span>Description</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="Description"
            rules="required"
          >
            <quill-editor
              v-model="form.description"
              :options="editorOption"
              :state="errors.length > 0 ? false : null"
            >
              <div id="toolbar" slot="toolbar">
                <button class="ql-bold">Bold</button>
                <button class="ql-italic">Italic</button>
                <button class="ql-list" value="bullet"></button>
                <button class="ql-list" value="ordered"></button>
              </div>
            </quill-editor>
            <!-- <b-form-textarea
              v-model="form.description"
              :state="errors.length > 0 ? false : null"
            ></b-form-textarea> -->
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Severity" label-for="Severity">
          <template #label>
            <span>Severity</span>
            <span
              class="text-danger font-weight-bold h5"
              style="margin-left: 3px"
              >*</span
            >
          </template>
          <validation-provider
            #default="{ errors }"
            name="severity"
            rules="required"
          >
            <b-form-select
              v-model="form.severity"
              :options="severity_options"
              :state="errors.length > 0 ? false : null"
            ></b-form-select>
            <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider>
        </b-form-group>

        <b-form-group label="Path" label-for="path">
          <!-- <validation-provider
            #default="{ errors }"
            name="path"
            rules="required"
          > -->
          <b-form-input id="path" v-model="form.path" name="path" />
          <!-- <small class="text-danger">{{ errors[0] }}</small> -->
          <!-- </validation-provider> -->
        </b-form-group>
        <b-form-group label="Port" label-for="port">
          <!-- <validation-provider
            #default="{ errors }"
            name="port"
            rules="required"
          > -->
          <b-form-input id="port" v-model="form.port" name="port" />
          <!-- <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider> -->
        </b-form-group>

        <b-form-group label="Protocol" label-for="Protocol">
          <!-- <validation-provider
            #default="{ errors }"
            name="Protocol"
            rules="required"
          > -->
          <b-form-select
            v-model="form.protocol"
            :options="protocol_options"
          ></b-form-select>
          <!-- <small class="text-danger">{{ errors[0] }}</small>
          </validation-provider> -->
        </b-form-group>

        <b-form-group label="Parameter" label-for="parameter">
          <b-form-input
            id="parameter"
            v-model="form.parameter"
            name="parameter"
          />
        </b-form-group>
        <b-form-group label="" label-for="Is Verified?">
          <b-form-checkbox
            id="checkbox-1"
            v-model="form.is_verified"
            name="is_verified"
            value="true"
            unchecked-value="false"
          >
            Is Verified?
          </b-form-checkbox>
        </b-form-group>
        <b-form-group label="" label-for="Is Exploited?">
          <b-form-checkbox
            id="checkbox-2"
            v-model="form.is_exploited"
            name="is_exploited"
            value="true"
            unchecked-value="false"
          >
            Is Exploited?
          </b-form-checkbox>
        </b-form-group>

        <b-form-group label="Recommendations" label-for="recommendation">
          <quill-editor v-model="form.recommendation" :options="editorOption2">
            <div id="toolbar2" slot="toolbar">
              <button class="ql-bold">Bold</button>
              <button class="ql-italic">Italic</button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-list" value="ordered"></button>
            </div>
          </quill-editor>
          <!-- <b-form-textarea v-model="form.recommendation"></b-form-textarea> -->
        </b-form-group>

        <b-form-group label="Evidence" label-for="nessus_evidence">
          <quill-editor v-model="form.nessus_evidence" :options="editorOption3">
            <div id="toolbar3" slot="toolbar">
              <button class="ql-bold">Bold</button>
              <button class="ql-italic">Italic</button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-list" value="ordered"></button>
            </div>
          </quill-editor>
          <!-- <b-form-textarea v-model="form.nessus_evidence"></b-form-textarea> -->
        </b-form-group>

        <b-form-group label="References" label-for="references">
          <!-- <b-form-textarea
            id="references"
            v-model="form.references"
            name="references"
          /> -->
          <quill-editor v-model="form.references" :options="editorOption4">
            <div id="toolbar4" slot="toolbar">
              <button class="ql-bold">Bold</button>
              <button class="ql-italic">Italic</button>
              <button class="ql-list" value="bullet"></button>
              <button class="ql-list" value="ordered"></button>
            </div>
          </quill-editor>
        </b-form-group>

        <b-button
          type="submit"
          variant="primary"
          :disabled="invalid || disable"
          class="my-1"
          size="sm"
        >
          <span v-if="isLoading"
            ><b-spinner type="border" small></b-spinner> Please wait</span
          >
          <span v-else>Add</span>
        </b-button>
      </b-form>
    </validation-observer>
  </b-card-code>
</template>

<script>
import vSelect from "vue-select";
import BCardCode from "@core/components/b-card-code/BCardCode.vue";
import {
  BTabs,
  BTab,
  BRow,
  BCol,
  BLink,
  BFormGroup,
  BFormInput,
  BForm,
  BButton,
  BFormTextarea,
  BInputGroupAppend,
  BInputGroup,
  BFormSelect,
  BFormCheckbox,
  BFormFile,
  BSpinner
} from "bootstrap-vue";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required } from "@validations";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import { quillEditor } from "vue-quill-editor";
import "quill/dist/quill.core.css";
import "quill/dist/quill.snow.css";
import "quill/dist/quill.bubble.css";

export default {
  components: {
    BCardCode,
    BForm,
    BFormInput,
    BFormTextarea,
    BFormGroup,
    BRow,
    BButton,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BInputGroupAppend,
    BInputGroup,
    BFormSelect,
    BFormCheckbox,
    BTabs,
    BTab,
    BFormFile,
    vSelect,
    quillEditor,
    BSpinner
  },
  data() {
    return {
      editorOption: {
        modules: {
          toolbar: "#toolbar",
        },
      },
      editorOption1: {
        modules: {
          toolbar: "#toolbar1",
        },
      },
      editorOption2: {
        modules: {
          toolbar: "#toolbar2",
        },
      },
      editorOption3: {
        modules: {
          toolbar: "#toolbar3",
        },
      },
      editorOption4: {
        modules: {
          toolbar: "#toolbar4",
        },
      },
      content: "",
      is_staff: this.$store.state.app.user.is_staff,
      severity_options: [
        { value: "critical", text: "Critical" },
        { value: "high", text: "High" },
        { value: "medium", text: "Medium" },
        { value: "low", text: "Low" },
        { value: "info", text: "Info" },
      ],
      protocol_options: [
        { value: "-", text: "-" },
        { value: "tcp", text: "TCP" },
        { value: "udp", text: "UDP" },
      ],
      vuln_options: [],
      owasp_options: [],
      path_or_port: "",
      isLoading: false,
      disable: false,
      form: {
        name: "",
        vm_name: "",
        cvss_score: "",
        owasp_categories: "",
        cve: "",
        severity: null,
        description: "",
        synopsis: "",
        recommendation: "",
        references: "",
        is_exploited: false,
        is_verified: false,
        path: "-",
        port: "-",
        parameter: "",
        nessus_evidence: "",
        //origin: "CUSTOM",
        asset_id: this.$route.params.id,
        vuln_id: "",
        protocol: "-",
      },
    };
  },
  created: function () {
    //  this.load(); --> not calling this due page load issues
  },
  methods: {
    load: function () {},
    onSelect(value) {
      if (value) {
        this.form.name = value.text; // Set the v-model to the value
      } else {
        this.form.name = ""; // Clear the v-model if the close button is clicked
      }
    },

    addVulnerability: function () {
      this.$refs.VulnAddForm.validate().then((success) => {
        this.disable = true;
        this.isLoading = true;
        if (success) {
          //   check if path or port
          // if (isNaN(this.path_or_port)) this.form.path = this.path_or_port;
          // else this.form.port = this.path_or_port;
          //   if(!this.form.asset_id)
          //         this.form.asset_id = this.$route.params.id
          //   this.form.origin = "CUSTOM"
          //call to axios
          var self = this;
          const options = {
            method: "POST",
            headers: { "content-type": "application/json" },
            data: this.form,
            url: process.env.VUE_APP_BASEURL + "asset/vulns/add-vulnerability/",
          };
          this.$http(options).then((res) => {
            console.log(res.data, "add vuln");
            if (res.data.status == "success") {
              // this.$router.push({
              //   name: "Scan Asset",
              //   params: { id: res.data.id },
              // });
              this.$router.push({
                params: { id: res.data.id },
                name: "Edit Vulnerability",
              });
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: "Successfully added vulnerability! ",
                  icon: "EditIcon",
                  variant: "success",
                },
              });
            } else {
              self.$toast({
                component: ToastificationContent,
                props: {
                  title: res.data.message,
                  icon: "EditIcon",
                  variant: "danger",
                },
              });
            }
            this.isLoading = false;
            this.disable = false;
          });
        }
      });
    },
    loadVulnerability(selectedOption) {
      console.log(selectedOption);
      var self = this;
      this.vuln_options.map(function (value, key) {
        console.log("Vl", value.text);
        if (value.text == selectedOption.value) {
          // self.form = value.full_details
          self.form.name = value.full_details.name;
          self.form.vm_name = value.full_details.vm_name;
          self.form.cvss_score = value.full_details.cvss_score;
          self.form.owasp_categories = value.full_details.owasp_categories;
          self.form.severity = value.full_details.severity;
          self.form.description = value.full_details.description;
          self.form.synopsis = value.full_details.synopsis;
          self.form.recommendation = value.full_details.recommendation;
          self.form.references = value.full_details.references;
          self.form.vuln_id = value.full_details.id;
          self.form.cve = value.full_details.cve;
          self.form.port = value.full_details.port;
          self.form.path = value.full_details.path;
          self.form.protocol = value.full_details.protocol;
        }
      });
    },
    fetchOptions(search, loading) {
      if (search.length) {
        // loading(true);
        // Load Users here
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/cvd-search?query=true" +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          // self.vuln_options = [];
          // res.data.results.map(function (value, key) {
          //   let a = {
          //     value: value.name,
          //     text: value.name,
          //     full_details: value,
          //   };
          //   self.vuln_options.push(a);
          // });
          const newOptions = res.data.results.map(function (value) {
            return {
              value: value.name,
              text: value.name,
              full_details: value,
            };
          });

          // Check if an option already exists in vuln_options
          const uniqueOptions = newOptions.filter((newOption) => {
            return !self.vuln_options.some((existingOption) => {
              return existingOption.value === newOption.value;
            });
          });
          // Add unique options to vuln_options
          self.vuln_options = self.vuln_options.concat(uniqueOptions);
        });
      }
    },
    fetchOwaspOptions(search, loading) {
      if (search.length) {
        const a_options = {
          method: "GET",
          headers: { "content-type": "application/json" },
          url:
            process.env.VUE_APP_BASEURL +
            "asset/owasp-search?query=true" +
            "&name=" +
            search,
        };
        var self = this;
        this.$http(a_options).then((res) => {
          self.owasp_options = [];
          res.data.results.map(function (value, key) {
            let a = {
              value: value.title,
              text: value.title,
              // full_details: value,
            };
            // console.log(res.data[key].profile.organization)
            self.owasp_options.push(a);
          });
        });
      }
    },
    goBack() {
      this.$router.go(-1);
    },
  },
};
</script>
<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>